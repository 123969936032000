<script>
import {reactive} from "vue";
import {onMounted} from 'vue';
import {adsService} from '@/services'
import moment from "moment";
import Swal from "sweetalert2";
import i18n from "@/i18n";
import {DATE_FORMAT} from "@/constants";
import {convertScToTimeFormat} from "@/helpers/moment";
import {ADS_TYPE} from "@/constants";
import Loading from "@/components/Loading.vue";
import Pagination from "@/components/pagination";

export default {
  components: {
    Pagination,
    Loading
  },

  setup(props, {emit}) {
    const state = reactive({
      countSort: 0,
      query: {
        page: 1,
        pageSize: 10,
        search: ''
      },
      loading: false,
      hasMore: true,
      data: [],
      pagination: null,
      timeLimit: 10,
      adsTotalTime: 100,
      timeLeft: 0,
      adsInfo: {},
      columns: [
        {
          sortable: false,
          label: '',
          type: 'stt',
          maxWidth: 200
        },
        {
          key: 'adsId',
          sortable: true,
          label: i18n.global.t('advertising.ads_id'),
          maxWidth: 200
        },
        {
          key: 'name',
          sortable: true,
          label: i18n.global.t('advertising.ads_name'),
          maxWidth: 200
        },
        {
          key: 'type',
          label: i18n.global.t('advertising.ads_type'),
          maxWidth: 200
        },
        {
          key: 'appearanceTime',
          sortable: true,
          label: i18n.global.t('advertising.displayTime'),
          maxWidth: 200
        },
        {
          key: 'active',
          label: i18n.global.t('t-enable-disable'),
          maxWidth: 200
        },
        {
          key: 'createdAt',
          sortable: true,
          label: i18n.global.t('t-registration-date'),
          maxWidth: 200
        },
        {
          key: 'updatedAt',
          sortable: true,
          label: i18n.global.t('t-last-update'),
          maxWidth: 200
        },
      ]
    });

    onMounted(() => {
      getAdsList();
    })

    async function getAdsList() {
      try {
        state.loading = true;
        const response = await adsService.getAdsList(querySearch);
        const {data, pagination} = response;
        state.data = data;
        state.pagination = pagination;
      } catch (e) {
        console.log(e)
      } finally {
        state.loading = false;
      }
    }

    const formatDate = (date) => {
      return moment(date).format(DATE_FORMAT)
    }

    /**
     *
     * @param {Object} ads
     */
    const navigateUpdateAds = (ads) => {
      emit('updateAds', ads);
    }

    const handleDeleteAds = async (row) => {
      const {id, disabled, name} = row;
      if (disabled) return;
      const accept = await Swal.fire({
        title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('deleted_message', {message: name})}</h2>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t('yes'),
        cancelButtonText: i18n.global.t('t-cancel'),
      });

      if (accept.value) {
        try {
          await adsService.deleteAds(id);
          await getAdsList();
        } catch (e) {
          let message = e?.data?.message || '';
          await Swal.fire({
            title: "",
            html: message,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    }

    const formatAppearanceTime = (appearanceTime) => {
      return `${appearanceTime}秒`
    }

    const handleSearch = () => {
      querySearch.page = 1;
      getAdsList();
    }

    const querySearch = reactive({
      search: '',
      page: 1,
      pageSize: 10,
      sortBy: 'createdAt',
      sortOrder: 'DESC',
    })

    const sort = (key) => {
      state.countSort ++;
      let typeDESC = true;
      if (querySearch.sortBy === key) {
        typeDESC = !(querySearch.sortOrder === 'DESC')
      }
      querySearch.sortBy = key;
      querySearch.sortOrder = typeDESC ? 'DESC' : "ASC";
      getAdsList();
    }

    const getClassActive = (key, type) => {
      let display = 'd-block';
      if (querySearch.sortBy === key && querySearch.sortOrder !== type) {
        display = "d-none";
      }

      return display;
    }

    const paginate = (pageNumber) => {
      querySearch.page = pageNumber;
      getAdsList();
    }

    const handleChangePerPage = (e) => {
      querySearch.pageSize = e.target.value;
      getAdsList();
    }

    return {
      ADS_TYPE,
      state,
      querySearch,
      formatDate,
      navigateUpdateAds,
      handleDeleteAds,
      convertScToTimeFormat,
      paginate,
      formatAppearanceTime,
      handleSearch,
      sort,
      getClassActive,
      handleChangePerPage,
      getAdsList
    }
  },
}
</script>

<template>
  <div style="padding: 2rem 2rem 0 2rem;">
    <div class="card-header border-0 p-0">
      <div class="row">
        <div class="col-sm">
          <div class="d-flex justify-content-sm-start" style="column-gap: 10px">
            <div class="search-box">
              <input type="text" v-model="querySearch.search"
                     class="form-control"
                     :placeholder='`${$t("t-search")}`'
                     @keyup.enter="handleSearch"
              />
              <i class="ri-search-line search-icon"></i>
            </div>
            <div>
              <b-button variant="primary" class="waves-effect waves-light" @click="handleSearch">
                <div class="flex">
                  {{ $t("t-search") }}
                </div>
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-sm-3 d-flex justify-content-end">
          <div class="dataTables_length">
            <label class="d-flex justify-content-center align-items-center" style="column-gap: 5px; font-size: 12px">
              <select
                  @change="handleChangePerPage"
                  v-model="querySearch.pageSize"
                  name="example_length"
                  aria-controls="example"
                  style="width: 85px;"
                  class="form-select form-control"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span style="min-width: 65px">{{ $t('entries_page') }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive" style="margin-top: 2rem;">
      <table class="table align-middle table-nowrap table-striped-columns mb-0 table-hover table-bordered">
        <thead class="table-light">
        <tr>
          <th class="sort" data-sort="currency_name" scope="col" v-for="(head, index) in state.columns"
              :key="`head_${index}`">
            <div class="d-flex w-100 justify-content-between align-items-center">
              <div>{{ head.label }}</div>
              <div v-if="head.sortable" @click="sort(head.key)" style="width: 24px">
                <div v-if="head.key !== querySearch.sortBy || state.countSort === 0" class="cursor-pointer">
                  <i class="ri-expand-up-down-fill font-16" style="color: #acadaf"></i>
                  <!--                  <i class="ri-arrow-up-down-line "></i>-->
                </div>
                <div class="d-flex flex-row" v-else>
                  <i class="ri-arrow-up-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                     style="padding: 0 4px; " :class="getClassActive(head.key, 'ASC')"/>
                  <i style="padding: 0 4px;"
                     class="ri-arrow-down-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                     :class="getClassActive(head.key, 'DESC')"/>
                </div>
              </div>
            </div>
          </th>
          <th scope="col"></th>
        </tr>
        </thead>

        <tbody>
        <template v-if="!state.loading">
          <tr v-for="(row, index) in state.data" :key="index">
            <td class="fs-12 text-normal" scope="row">
              {{ index + 1 }}
            </td>
            <td data-bs-toggle="tooltip" data-bs-placement="bottom" :title="row?.uuid || ''"
                class="text-truncate fs-12 fw-medium text-normal" style="max-width: 200px;">
              {{ row.adsId }}
            </td>
            <td data-bs-toggle="tooltip" data-bs-placement="bottom" :title="row?.name || ''"
                class="text-truncate fs-12 fw-medium text-normal" style="max-width: 200px;">
              {{ row.name }}
            </td>
            <td
                class="text-truncate fs-12 fw-medium text-normal" style="max-width: 200px;">
              {{ ADS_TYPE[row.type] }}
            </td>

            <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                :title="formatAppearanceTime(row.appearanceTime || 0)"
                class="text-truncate fs-12 fw-medium text-normal" style="max-width: 200px;">{{
                formatAppearanceTime(row.appearanceTime || 0)
              }}
            </td>
            <td>
              <div v-if="row.active" class="d-flex flex-row align-items-center">
                <i class="bx bx-check-circle fs-18 text-success"></i>
                <p class="mb-0 ps-1 fw-semibold text-normal">
                  {{ $t("t-enable") }}
                </p>
              </div>
              <div v-else class="d-flex flex-row align-items-center">
                <i class="bx bx-x-circle fs-18 text-danger"></i>
                <p class="mb-0 ps-1 fw-semibold text-normal">
                  {{ $t("t-disable") }}
                </p>

              </div>
            </td>
            <td class="fs-12 fw-medium text-normal">{{ formatDate(row.createdAt) }}</td>
            <td class="fs-12 fw-medium text-normal">{{ formatDate(row.updatedAt) }}</td>

            <td>
              <button class="btn btn-primary ms-2 w-sm" size="sm" @click="navigateUpdateAds(row)"
                      :disabled="row.disabled">
                {{ $t("t-edit") }}
              </button>
              <button :disabled="row.disabled" size="sm" class="btn btn-danger ms-2 w-sm"
                      @click="() => handleDeleteAds(row)">
                {{ $t("t-delete") }}
              </button>
            </td>
          </tr>

          <tr v-if="!state.data || state.data.length === 0">
            <td :colspan="9" class="text-center text-no-data p-5">
              {{ $t('t-no-data') }}
            </td>
          </tr>
        </template>
        <tr v-else>
          <td :colspan="9" class="text-center p-5">
            <Loading/>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="w-100" :class="state.data.length ? 'mt-3' : ''" v-if="state.pagination"
           style="border-bottom: 0; border-bottom-color: transparent;">
        <div class="d-flex justify-content-end">
          <Pagination :pagination="state.pagination" @onChangePage="paginate" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.set-color {
  background-color: rgba(54, 61, 72, 0.1) !important;
  color: #363d48 !important;
}

.total-container {
  width: 100%;
  justify-content: flex-end;
}

.gap-column {
  column-gap: 20px;
}

.gap-row {
  row-gap: 6px;
}
</style>
