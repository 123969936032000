<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import AdsList from "./ads-list.vue"
import AdsCreate from "@/components/advertisement/create.vue"
import {reactive, ref} from "vue";
import Swal from "sweetalert2";
import i18n from "@/i18n";
import {cloneDeep, isEqual} from "lodash";

export default {
  setup() {
    const listAds = ref(null);
    const state = reactive({
      adsInfo: {},
      isCreate: true
    })

    const addCreate = ref(null);

    const onUpdateAds = async (ads) => {
      const formData = addCreate.value.state.form;
      const {active, appearanceTime, name, type, url} = cloneDeep(state.adsInfo);
      let checkSame = !state.isCreate ? isEqual(formData, {active, appearanceTime, name, type, url}) : true;

      const formCreate = addCreate.value.state.form;
      const checkObjectEmpty = Object.keys(formCreate).some(item => {
        if (item === 'active') {
          return !formCreate[item];
        } else {
          return formCreate[item] !== ''
        }
      })

      let accept = true;

      if (checkObjectEmpty && state.isCreate || !checkSame) {
        accept = await Swal.fire({
          title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('t-override-input', {message: '広告'})}</h2>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: i18n.global.t('yes'),
          cancelButtonText: i18n.global.t('t-cancel'),
        });
      }

      if (typeof accept === 'object' && !accept.isConfirmed) {
        return;
      }
      state.adsInfo = ads;
      state.isCreate = false;
    }

    const handleCreateSuccessAds = () => {
      state.adsInfo = {
        "url": "",
        "appearanceTime": "",
        "name": "",
        "active": true,
        "type": ""
      }
      state.isCreate = true;
      listAds.value.getAdsList()
    }

    return {
      listAds,
      addCreate,
      state,
      onUpdateAds,
      handleCreateSuccessAds
    }
  },
  data() {
    return {
      title: this.$i18n.t('t-ads-management'),
      items: [
        {
          text: this.$i18n.t('t-ads-management'),
          active: true
        },
        {
          text: this.$i18n.t('t-ads-name'),
          active: true,
        },
      ],
      tabIdActive: '',
    }
  },
  components: {
    Layout,
    PageHeader,
    AdsList,
    AdsCreate
  },
  mounted() {
  },
  methods: {
    toCreate() {
      this.$router.push({ name: `advertisement-management-create`, query: { role: 'ADMIN' } })
    },
    toHistory() {
      this.$router.push({ name: `advertisement-history` })
    }
  },

}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="rounded-3">
      <div class="row">
        <div class="col-md-3">
          <div class="card">
            <div class="card-header card-header-radius">
              広告情報
            </div>
            <div>
              <AdsCreate ref="addCreate" :ads-info="state.adsInfo" :is-create="state.isCreate" @onCreateSuccess="handleCreateSuccessAds" />
            </div>
          </div>

        </div>
        <div class="col-md-9">
          <div class="card pb-3">
            <div class="card-header card-header-radius">
              {{ $t('t-ads-name') }}
            </div>
            <AdsList @updateAds="onUpdateAds" ref="listAds" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.card-header-radius {
  border-radius: 10px 10px 0 0;
  font-size: 16px;
  color: var(--vz-heading-color);
}
</style>
